import 'core-js/modules/es.symbol'
import 'core-js/modules/es.array.includes'
import 'core-js/modules/es.number.is-nan'

import { render, h } from 'preact'
import { App } from './components/App'
import { getHeadFileCount, removeHeadFile } from './common/utils'
import GlobalStyle from './globalStyle'
import AppProvider from './common/context'

let isLoaded = false
const commonURL = 'fesdk.nexon.com/ngm-execution-layer'

const renderApp = () => {
  render(
    <>
      <GlobalStyle />
      <AppProvider>
        <App />
      </AppProvider>
    </>,
    document.body,
  )
}

function isJapanPublisher() {
  const hostIsJapan = ['jp.nexon.com', 'nexon.co.jp'].find((host) => {
    const regxp = new RegExp(host)
    return regxp.test(location.host)
  })

  if (hostIsJapan) {
    return true
  }

  const langHtml = document.querySelector('html')
  if (langHtml && langHtml.lang === 'ja') {
    return true
  }

  return false
}

window.addEventListener('ngm-loaded', () => {
  const URL_LAYERS_CMD: { [cmdKey: string]: (hash: string) => void } = {
    '\!\/ngm-layer\/download(/.*)?': (hash: string) => {
      try {
        const titleUrlString = hash.split('!/ngm-layer/download/').filter((e) => !!e)[0]
        if (titleUrlString) {
          const title = titleUrlString === '!/ngm-layer/download' ? 'NGM' : decodeURIComponent(titleUrlString)
          const publisher = isJapanPublisher() || title === 'jp' ? 'NexonJapan' : 'NexonKorea'
          const handler = setInterval(() => {
            try {
              if (window.NgmLayer && window.NgmLayer.openNgmLayer) {
                window.NgmLayer.openNgmLayer(title || 'NGM', { publisher, scene: 'download' })
                clearInterval(handler)
              }
            } catch (e) {
              console.error(e)
              clearInterval(handler)
            }
          }, 10)
        }
      } catch (e) {
        console.error(e)
      }
    },
  }
  const hashs = window.location.hash.split('#').filter((k) => k !== '')
  const cmds = Object.keys(URL_LAYERS_CMD)
  cmds.forEach((cmd) => {
    const regexp = new RegExp(cmd)
    const targetHash = hashs.find((hash) => regexp.test(hash))
    if (targetHash && cmd) {
      URL_LAYERS_CMD[cmd](targetHash)
    }
  })
})

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', () => {
    isLoaded = true
  })
} else {
  isLoaded = true
}

const loadCheckerInterval = setInterval(() => {
  if (isLoaded) {
    if (getHeadFileCount(`${commonURL}/bundle.js`, 'script') > 1) {
      removeHeadFile(`${commonURL}/bundle.js`, 'script', 1)
    }

    renderApp()
    clearInterval(loadCheckerInterval)
    window.dispatchEvent(new Event('ngm-loaded'))
  }
}, 200)
