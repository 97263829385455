import styled from 'styled-components'
import CommonStyle from '../../../common/styled/CommonStyle.styled'

const NgmDownloadText = styled(CommonStyle) <{ color: string }>`
  color: ${({ color = '#51555d' }) => color};
  letter-spacing: -0.48px;
  font-weight: normal;
  font-size: 14px;
  mark {
    color: #02A7F0;
    background: none;
  }
`

export default NgmDownloadText
