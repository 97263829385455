import styled from 'styled-components'
import { PublisherType } from '@/declaration'
import CloseButton from '../common/CloseButton/CloseButton'
import NgmInstallContent from '../NgmInstallContent.styled'
import NgmInstallLayer from '../NgmInstallLayer.styled'
import { sendLog } from '@/common/utils'
import { t } from '@/common/i18n'

type MainForMacPropsType = {
  gameName?: string
  isSupportWindowGame: boolean
  isSupportMacGame: boolean
  publisher: PublisherType
  onConfirm: (value: boolean) => void
}

const DIMMLayerBackground = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);

  z-index: 9999999;
`

const MacContentWrapper = styled.div`
  display: block;
  min-width: 30rem;
  min-height: 10rem;
`

const MacGameTitle = styled.h1`
  margin-top: 12px;
  margin-bottom: 1.3rem;
  min-height: 2.02rem;
  font-size: 1.3rem;
  text-align: center;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.43px;
  text-align: center;
  color: #17191c;
  padding: 0 3rem;
`

const HightlightContent = styled.div`
  font-size: 1.2rem;
  text-align: center;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.43px;
  text-align: center;
  color: #51555d;
  padding: 0 3rem;
`

const DescriptionContent = styled(HightlightContent)`
  font-weight: normal;
`

const ButtonText = styled.div <{ colorset: 'light' | 'dark' }>`
  display: block;
  position: absolute;
  top: 1.1rem;
  min-width: 100%;
  text-align: center;
  font-size: 1.17rem;
  font-weight: 500;
  color: ${(props) => (props.colorset === 'dark' ? '#c6ccd7' : '#17191c')};
  transition: all .2s ease-out;
`

const ButtonDescription = styled.div <{ colorset: 'light' | 'dark' }>`
  display: block;
  position: absolute;
  bottom: -0.3rem;
  min-width: 100%;
  opacity: 0;
  font-size: 0.715rem;
  color: #a1a7b5;
  transition: all .2s ease-out;
`

const NexonPlugMacConfirmButton = styled.button <{ colorset: 'light' | 'dark' }>`
  display: inline-block;
  position: relative;
  cursor: pointer;
  min-width: 230px;
  min-height: 62px;
  border: transparent 1px;
  border-radius: 4px;
  background: ${(props) => (props.colorset === 'dark' ? '#17191c' : '#e3e9f7')};
  ${ButtonText} {
    color: ${(props) => (props.colorset === 'dark' ? '#ffffff' : null)};
  }

  &:hover {
    ${ButtonText} {
      top: 0.5rem;
      color: ${(props) => (props.colorset === 'dark' ? '#c6ccd7' : '#17191c')};
    }
    ${ButtonDescription} {
      bottom: 0.5rem;
      opacity: 1!important;
    }
  }

  & +& {
    margin-left: 0.52rem;
  }
`

const NexonPlugMacFullSizeConfirmButton = styled.button <{ colorset: 'light' | 'dark' }>`
  display: inline-block;
  position: relative;
  cursor: pointer;
  min-width: 100%;
  min-height: 62px;
  border: transparent 1px;
  border-radius: 4px;
  background: ${(props) => (props.colorset === 'dark' ? '#17191c' : '#eef1f6')};
  color: ${(props) => (props.colorset === 'dark' ? '#ffffff' : '#17191c')};
  ${ButtonText} {
      top: 1rem;
    }
  font-size: 1rem;
  &:hover {
    color: ${(props) => (props.colorset === 'dark' ? '#c6ccd7' : '#17191c')};
    background: ${(props) => (props.colorset === 'dark' ? '#17191c' : '#e3e9f7')};
  }
`

const ButtonWrapper = styled.div`
  margin-top: 2.08rem;
  margin-bottom: 1.82rem;
`

const DimmLayer = (props: { children: JSX.Element }) => {
  const { children } = props

  const closeNGM = (e?: Event) => {
    if (e) {
      e.stopPropagation()
    }

    if (typeof window.NGM !== 'undefined') window.NGM.ClearNGMStatusTimer()
    window.NgmLayer.closeNgmLayer()
    return false
  }
  return (
    <DIMMLayerBackground onClick={(e: any) => closeNGM(e)}>
      {children}
    </DIMMLayerBackground>
  )
}

const MainForMac = (props: MainForMacPropsType) => {
  const {
    gameName, isSupportMacGame, onConfirm,
  } = props

  const closeNGM = (e?: Event) => {
    if (e) {
      e.stopPropagation()
    }

    if (typeof window.NGM !== 'undefined') window.NGM.ClearNGMStatusTimer()
    window.NgmLayer.closeNgmLayer()
    return false
  }

  const moveToNexonPlugWebsite = () => {
    window.open('https://nexonplug.nexon.com/', '_blank')
    closeNGM()
  }

  const onConfirmExecuteGame = () => {
    sendLog('C', 'ngmlayer_2_GameStart', gameName || '')
    if (typeof window.NGM !== 'undefined') window.NGM.ExecuteNGMFromLayer()
    if (onConfirm) {
      onConfirm(true)
    }
  }

  if (!isSupportMacGame) {
    return (
      <DimmLayer>
        <NgmInstallLayer id="ngmInstallLayer" onClick={(e: any) => e.stopPropagation()}>
          <NgmInstallContent>
            <CloseButton />
            <MacContentWrapper>
              <MacGameTitle>{gameName || ' '}</MacGameTitle>
              <HightlightContent>{t('windows_only_game' || 'Windows에서 이용할 수 있습니다.')}</HightlightContent>
              <ButtonWrapper>
                <NexonPlugMacFullSizeConfirmButton colorset="dark" onClick={closeNGM}>
                  {t('confirm_text' || '확인')}
                </NexonPlugMacFullSizeConfirmButton>
              </ButtonWrapper>
            </MacContentWrapper>
          </NgmInstallContent>
        </NgmInstallLayer>
      </DimmLayer>
    )
  }

  return (
    <DimmLayer>
      <NgmInstallLayer id="ngmInstallLayer" onClick={(e: any) => e.stopPropagation()}>
        <NgmInstallContent>
          <CloseButton />
          <MacContentWrapper>
            <MacGameTitle>{gameName || ' '}</MacGameTitle>
            <HightlightContent>{t('check_for_installed_nxplug_question' || '이 컴퓨터에 넥슨플러그가 설치되어 있나요?')}</HightlightContent>
            <DescriptionContent>{t('need_to_install_for_nxplug' || '게임 이용을 위해 넥슨플러그가 필요합니다.')}</DescriptionContent>
            <ButtonWrapper>
              <NexonPlugMacConfirmButton onClick={moveToNexonPlugWebsite}>
                <ButtonText>{t('no_text' || '아니오')}</ButtonText>
                <ButtonDescription>{t('move_to_nxplug_website' || '넥슨플러그 설치 페이지로 이동합니다')}</ButtonDescription>
              </NexonPlugMacConfirmButton>
              <NexonPlugMacConfirmButton colorset="dark" onClick={onConfirmExecuteGame}>
                <ButtonText colorset="dark">{t('yes_text' || '네')}</ButtonText>
                <ButtonDescription colorset="dark">{t('execute_nxplug' || '넥슨플러그를 실행합니다')}</ButtonDescription>
              </NexonPlugMacConfirmButton>
            </ButtonWrapper>
          </MacContentWrapper>
        </NgmInstallContent>
      </NgmInstallLayer>
    </DimmLayer>
  )
}

export default MainForMac
