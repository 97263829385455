import { FunctionComponent, h } from 'preact'
import { useState, useEffect } from 'preact/hooks'
import SectionWait from '../SectionWait/SectionWait'
import SetionDownload from '../SetionDownload/SetionDownload'

type SubProps = {
  showGuide: ({ target }: { target: EventTarget | null }) => void
}

const Sub: FunctionComponent<SubProps> = ({ showGuide }) => {
  const [flag, setFlag] = useState(false)
  let el = null

  useEffect(() => {
    if (!flag) {
      const timeoutSet = setTimeout(() => {
        clearTimeout(timeoutSet)
        setFlag(true)
      }, 5 * 1000)
    }
  })

  el = !flag ? <SectionWait /> : <SetionDownload showGuide={showGuide} />
  return el
}

export default Sub
