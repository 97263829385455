import { h } from 'preact'
import { useContext } from 'preact/hooks'
import styled from 'styled-components'
import { t } from '../../../common/i18n'
import { AppStateContext } from '../../../common/context'
import GuideFirst from '../GuideFirst/GuideFirst'
import GuideSecond from '../GuideSecond/GuideSecond'
import GuideThird from '../GuideThird/GuideThird'
import GuideFourth from '../GuideFourth/GuideFourth'
import StartButton from '../../common/StartButton/StartButton'
import NgmNoticeContainer from './styled/NgmNoticeContainer.styled'
import NgmNoticeHeaderStyle from './styled/NgmNoticeHeaderStyle.styled'
import NgmSpanStyle from './styled/NgmSpanStyle.styled'
import NgmStrongStyle from './styled/NgmStrongStyle.styled'
import GuideDivider from './styled/GuideDivider.styled'

const GuideWrapper = styled.div<{ $isShow: boolean }>`
  display: ${(props) => (props.$isShow ? 'block' : 'none')};
`

const Guide = ({ isShow }: { isShow: boolean }) => {
  const { os, title } = useContext(AppStateContext)

  return (
    <GuideWrapper $isShow={isShow}>
      <NgmNoticeHeaderStyle data-testid="ngm-notice-header">
        <NgmSpanStyle>
          {title && t('notice_welcome_en')}
          <NgmStrongStyle>{title || t('notice_welcome_default')}</NgmStrongStyle>
          {title && t('notice_welcome_kr')}
        </NgmSpanStyle>
      </NgmNoticeHeaderStyle>
      <NgmNoticeContainer os={os} data-os={os}>
        <GuideFirst />
        <GuideDivider />
        <GuideSecond />
        <GuideDivider />
        <GuideThird />
        <GuideDivider />
        <GuideFourth />
      </NgmNoticeContainer>
      <StartButton isShow={isShow} />
    </GuideWrapper>
  )
}

export default Guide
