import { FunctionComponent, h, Fragment } from 'preact'
import { StateUpdater, useContext, useState } from 'preact/hooks'
import { useEffect } from 'react'
import { AppDispatchContext, AppStateContext } from '../common/context'
import { sendLog } from '../common/utils'
import CloseButton from './common/CloseButton/CloseButton'
import Sub from './main/Sub/Sub'
import Main from './main/Main/Main'
import Guide from './guide/Guide/Guide'
import NgmInstallLayer from './NgmInstallLayer.styled'
import NgmInstallContent from './NgmInstallContent.styled'
import Agreement from './agreement/Agreement'
import MainForMac from './mainForMac'
import { PublisherType } from '@/declaration'

const App: FunctionComponent = () => {
  const [currentPublisher, setCurrentPublisher] = useState<PublisherType>('NexonKorea')
  const [isShowGuide, setIsShowGuide] = useState(false)
  const [isShowAgreement, setIsShowAgreement] = useState(false)
  const [isInit, setIsInit] = useState(false)
  const [supportWindows, setSupportWindows] = useState(true)
  const [supportMac, setSupportMac] = useState(false)
  const [iamMac, setIamMac] = useState(false)
  const isMac = false // iamMac || /(Mac|iPhone|iPod|iPad)/i.test(window.navigator.userAgent)

  const { title } = useContext(AppStateContext)
  const setAppInfo = useContext(AppDispatchContext)

  window.EXECUTION_LAYER_NEXONPLUG_DOWNLOAD_URL = {
    KRPC: 'https://platform.nexon.com/launcher/installer/win/KRPC/Install_NexonPlug.exe',
    JPPC: 'https://platform.nexon.com/launcher/installer/win/JPPC/Install_NexonPlug.exe',
  }

  window.ngmIgnoreIntegratedInstallerURLs = [
    'warsofprasia.beanfun.com',
    'dev.nexpace.io',
    'test.nexpace.io',
    'qa.nexpace.io',
    'stage.nexpace.io',
    'nexpace.io',
  ]

  window.NgmLayer = {
    ...window.NgmLayer,
    openNgmLayer(titleValue, options) {
      sendLog('V', 'ngmlayer_1', titleValue || '')
      setIsInit(true)

      setAppInfo((prev) => ({
        ...prev,
        title: titleValue || '',
        platform: options.publisher,
      }))

      if (typeof options !== 'object') {
        return
      }

      if (options.publisher && options.publisher === 'NexonJapan') {
        setCurrentPublisher('NexonJapan')
      }

      if (options.supportWindows) {
        setSupportWindows(options.supportWindows)
      } else {
        setSupportWindows(false)
      }

      if (options.supportMac) {
        setSupportMac(options.supportMac)
      } else {
        setSupportMac(false)
      }
      if (window.iamMac) {
        setIamMac(true)
      } else {
        setIamMac(false)
      }

      if (options.scene) {
        if (options.scene === 'download') {
          setIsShowGuide(true)
        }
      }
    },
    closeNgmLayer() {
      if (!isShowGuide) sendLog('C', 'ngmlayer_1_close', title)
      else sendLog('C', 'ngmlayer_2_close', title)

      setIsShowGuide(false)
      setIsInit(false)
      setIamMac(false)
      if (typeof window.NGM !== 'undefined') window.NGM.ClearNGMStatusTimer()
    },
  }

  const showGuide = () => {
    sendLog('C', 'ngmlayer_1_install', title)
    if (typeof window.NGM !== 'undefined' && !isShowAgreement) {
      // 플랫폼 고정
      const platform = 'KRPC' // currentPublisher === 'NexonKorea' ? 'KRPC' : 'JPPC'
      let downloadUrl = 'https://platform.nexon.com/launcher/installer/win/KRPC/Install_NexonPlug.exe'
      if (window.EXECUTION_LAYER_NEXONPLUG_DOWNLOAD_URL && window.EXECUTION_LAYER_NEXONPLUG_DOWNLOAD_URL[platform]) {
        downloadUrl = window.EXECUTION_LAYER_NEXONPLUG_DOWNLOAD_URL[platform]
      }

      try {
        if (/(Mac|iPhone|iPod|iPad)/i.test(window.navigator.userAgent)
          || window.ngmIgnoreIntegratedInstallerURLs?.some((url) => (new RegExp(url)).test(window.location.href))) {
          downloadUrl = window.NGM.NGM_SETUP_URL
          console.log('execution - ignore integrated installer')
        }
      } catch (e) {
        downloadUrl = window.NGM.NGM_SETUP_URL
      }

      window.NGM.DownloadResource(downloadUrl)
    }
    setIsShowGuide(true)
    sendLog('V', 'ngmlayer_2', title)
  }

  useEffect(() => {
    if (currentPublisher === 'NexonJapan') {
      setIsShowAgreement(true)
    }
  }, [currentPublisher])

  const onConfirmMac = () => {
    window.NgmLayer.closeNgmLayer()
  }
  const el = isMac ? (
    <MainForMac
      gameName={title}
      isSupportMacGame={supportMac}
      isSupportWindowGame={supportWindows}
      publisher={currentPublisher}
      onConfirm={onConfirmMac}
    />
  ) : (
    <NgmInstallLayer id="ngmInstallLayer">
      <NgmInstallContent>
        <CloseButton />
        <>
          <Guide isShow={isShowGuide && !isShowAgreement} />
          <Agreement isShow={isShowGuide && isShowAgreement} close={setIsShowAgreement} title={title} />
          {!isShowGuide && (
            <>
              <Main />
              <Sub showGuide={() => showGuide()} />
            </>
          )}
        </>
      </NgmInstallContent>
    </NgmInstallLayer>
  )

  return isInit ? el : null
}

export default App
export { App }
