import { useContext } from 'preact/hooks'
import { AppStateContext } from '../../../common/context'
import { currentLang } from '../../../common/i18n'
import { getStaticURL } from '../../../common/utils'
import Img from './styled/Image.styled'

type ImageProps = {
  fileName: string
  width: number
  isGlobal: boolean | undefined
}
const Image = ({ fileName, width, isGlobal }: ImageProps) => {
  const { os } = useContext(AppStateContext)
  const osType = isGlobal ? `global-${os}` : os
  const source2x = `${getStaticURL()}/assets/imgs/${osType}/${currentLang}/${fileName}@2x.png`
  const source = `${getStaticURL()}/assets/imgs/${osType}/${currentLang}/${fileName}.png`

  return (
    <picture style={{ display: 'inline-block' }}>
      <source media="(min-width: 1080px)" srcSet={source2x} />
      <source media="(max-width: 1079px)" srcSet={source} />
      <Img src={source2x} alt={fileName} $width={width} />
    </picture>
  )
}

export default Image
