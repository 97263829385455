import { getOS } from './utils'

type TranslationType = {
  [os in ReturnType<typeof getOS>] : {
    [key: string]: {
      [key: string]: string
    }
  }
}

const translation: TranslationType = {
  windows: {
    ko: {
      common_game: '게임',
      common_game_default: '게임이 곧 시작됩니다!',
      install_title: '곧 시작됩니다!',
      install_wait: '잠시만 기다려주세요~',
      install_desc_1: '자동으로 시작되지 않는다면 ?',
      install_desc_2: '넥슨 통합 런처 <mark>넥슨플러그</mark>를 설치하세요.',
      notice_welcome_en: '',
      notice_welcome_kr: '에 오신 것을 환영합니다.',
      notice_welcome_default: '게임에 오신것을 환영합니다.',
      notice_desc_1_a: '다운로드 한 ',
      notice_desc_1_b: 'Install_NexonPlug.exe',
      notice_desc_1_c: '를 실행하여 설치해주세요.',
      notice_desc_2_a: '<strong>동의 및 설치 버튼</strong>을 선택하여 ',
      notice_desc_2_b: '설치를 진행해 주세요.',
      notice_desc_2_c: '',
      notice_desc_3_a: '설치가 완료되면 <strong>로그인</strong>을 진행해 주세요.',
      notice_desc_3_b: '게임이 자동으로 실행됩니다.',
      notice_desc_3_c: '',
      notice_desc_4_a: '게임이 실행되지 않으면, ',
      notice_desc_4_b: '아래의 <strong>지금시작</strong>을 클릭하세요.',
      notice_desc_4_c: '',
      btn_start: '지금시작',
      btn_install: '다운로드 및 설치',
      confirm_text: '확인',
      yes_text: '네',
      no_text: '아니오',
      windows_only_game: 'Windows에서 이용할 수 있습니다.',
      check_for_installed_nxplug_question: '이 컴퓨터에 넥슨플러그가 설치되어 있나요?',
      need_to_install_for_nxplug: '게임 이용을 위해 넥슨플러그가 필요합니다.',
      move_to_nxplug_website: '넥슨플러그 설치 페이지로 이동합니다',
      execute_nxplug: '넥슨플러그를 실행합니다',
    },
    zhhant: {
      common_game: '遊戲',
      common_game_default: '遊戲即將開始！',
      install_title: '即將開始！',
      install_wait: '請稍候~',
      install_desc_1: '如果沒有自動開始？',
      install_desc_2: '請安裝 NEXON Game Manager',
      notice_welcome_en: '歡迎來到 ',
      notice_welcome_kr: '。',
      notice_welcome_default: '歡迎來到遊戲。',
      notice_desc_1_a: '請執行並安裝您所下載的 ',
      notice_desc_1_b: 'Install_NGM.exe',
      notice_desc_1_c: '。',
      notice_desc_2_a: '請在使用者帳戶控制中允許使用 ',
      notice_desc_2_b: 'Nexon Game Manager',
      notice_desc_2_c: '。',
      notice_desc_3_a: '完成安裝後請點擊 ',
      notice_desc_3_b: '[確認]',
      notice_desc_3_c: '。',
      notice_desc_4_a: '確認安裝後請點擊下面的 ',
      notice_desc_4_b: '[現在開始]',
      notice_desc_4_c: '。',
      btn_start: '現在開始',
      btn_install: '下載並安裝',
      confirm_text: '確認',
      yes_text: '是',
      no_text: '否',
      windows_only_game: '在 Windows 上可用。',
      check_for_installed_nxplug_question: '此電腦是否已安裝 NEXON Plug？',
      need_to_install_for_nxplug: '您需要 NEXON PLUG 才能遊玩此遊戲',
      move_to_nxplug_website: '進入 NEXON PLUG 安裝頁面。',
      execute_nxplug: '啟動 NEXON PLUG。',
    },
    en: {
      common_game: 'The game',
      common_game_default: 'The game will start soon!',
      install_title: 'Will start soon!',
      install_wait: 'Please wait for a moment.',
      install_desc_1: 'If it doesn\'t start automatically,',
      install_desc_2: 'install NEXON Game Manager.',
      notice_welcome_en: 'Welcome to ',
      notice_welcome_kr: '',
      notice_welcome_default: 'Welcome to the game!',
      notice_desc_1_a: 'Execute and install the downloaded ',
      notice_desc_1_b: 'Install_NGM.exe',
      notice_desc_1_c: '.',
      notice_desc_2_a: 'Allow ',
      notice_desc_2_b: 'Nexon Game Manager',
      notice_desc_2_c: ' in \nuser account control.',
      notice_desc_3_a: 'When the installation is complete, \nclick ',
      notice_desc_3_b: '[OK]',
      notice_desc_3_c: '.',
      notice_desc_4_a: 'Click ',
      notice_desc_4_b: '[Start Now]',
      notice_desc_4_c: '.',
      btn_start: 'Start Now',
      btn_install: 'Download and Install',
      confirm_text: 'OK',
      yes_text: 'YES',
      no_text: 'NO',
      windows_only_game: 'Available on Windows.',
      check_for_installed_nxplug_question: 'Is NEXON Plug installed on this computer?',
      need_to_install_for_nxplug: 'You need NEXON PLUG to play the game.',
      move_to_nxplug_website: 'Go to the NEXON PLUG installation page.',
      execute_nxplug: 'Launch NEXON PLUG.',
    },
    ja: {
      common_game: 'ゲーム',
      common_game_default: 'ゲームがまもなくスタートします！',
      install_title: 'まもなくスタートします！',
      install_wait: '少々お待ちください。',
      install_desc_1: '自動でスタートしない場合はNEXON Game',
      install_desc_2: 'Managerをインストールしてください。',
      notice_welcome_en: '',
      notice_welcome_kr: ' へようこそ!',
      notice_welcome_default: 'ゲームへようこそ。',
      notice_desc_1_a: 'ダウンロードした',
      notice_desc_1_b: 'Install_NGM.exe',
      notice_desc_1_c: 'を実行してインストールしてください。',
      notice_desc_2_a: 'ユーザーアカウント制御で',
      notice_desc_2_b: 'Nexon Game Manager',
      notice_desc_2_c: 'を許可してください。',
      notice_desc_3_a: 'インストールが完了したら',
      notice_desc_3_b: '[確認]',
      notice_desc_3_c: 'をクリックしてください。',
      notice_desc_4_a: 'インストールを確認した後、',
      notice_desc_4_b: '[スタート]',
      notice_desc_4_c: 'をクリックしてください。',
      btn_start: 'スタート',
      btn_install: 'ダウンロード',
      confirm_text: '確認',
      yes_text: 'はい',
      no_text: 'いいえ',
      windows_only_game: 'Windowsで利用可能。',
      check_for_installed_nxplug_question: 'NEXON Plugはインストールされていますか？',
      need_to_install_for_nxplug: 'ゲームをプレイするにはNEXON PLUGが必要です。',
      move_to_nxplug_website: 'NEXON PLUGインストールページに移動します。',
      execute_nxplug: 'NEXON PLUGを起動する。',
    },
  },
  mac: {
    ko: {
      common_game: '게임',
      common_game_default: '게임이 곧 시작됩니다!',
      install_title: '곧 시작됩니다!',
      install_wait: '잠시만 기다려주세요~',
      install_desc_1: '자동으로 시작되지 않는다면 ?',
      install_desc_2: '게임 이용을 위해 넥슨플러그가 필요합니다.',
      notice_welcome_en: '',
      notice_welcome_kr: ' 설치 및 실행 안내',
      notice_welcome_default: '게임에 오신것을 환영합니다.',
      notice_desc_1_a: '다운로드 한 ',
      notice_desc_1_b: 'Install_Nexonplug.pkg',
      notice_desc_1_c: '를\n 실행하여 설치해주세요.',
      notice_desc_2_a: '소프트웨어 사용권 계약에 동의해주세요.',
      notice_desc_2_b: '',
      notice_desc_2_c: '동의 후 설치 가능합니다.',
      notice_desc_3_a: '설치가 완료되면 ',
      notice_desc_3_b: '[닫기]',
      notice_desc_3_c: '를 클릭하세요.',
      notice_desc_4_a: '설치 확인 후 아래의 ',
      notice_desc_4_b: '[지금시작]',
      notice_desc_4_c: '을 클릭하세요.',
      btn_start: '지금시작',
      btn_install: '다운로드 및 설치',
      confirm_text: '확인',
      yes_text: '네',
      no_text: '아니오',
      windows_only_game: 'Windows에서 이용할 수 있습니다.',
      check_for_installed_nxplug_question: '이 컴퓨터에 넥슨플러그가 설치되어 있나요?',
      need_to_install_for_nxplug: '게임 이용을 위해 넥슨플러그가 필요합니다.',
      move_to_nxplug_website: '넥슨플러그 설치 페이지로 이동합니다',
      execute_nxplug: '넥슨플러그를 실행합니다',
    },
    zhhant: {
      common_game: '遊戲',
      common_game_default: '遊戲即將開始！',
      install_title: '即將開始！',
      install_wait: '請稍候~',
      install_desc_1: '如果沒有自動開始？',
      install_desc_2: '請安裝 NEXON Game Manager',
      notice_welcome_en: '歡迎來到 ',
      notice_welcome_kr: '。',
      notice_welcome_default: '歡迎來到遊戲。',
      notice_desc_1_a: '請執行並安裝您所下載的 ',
      notice_desc_1_b: 'Install_NGM.exe',
      notice_desc_1_c: '。',
      notice_desc_2_a: '請在使用者帳戶控制中允許使用 ',
      notice_desc_2_b: 'Nexon Game Manager',
      notice_desc_2_c: '。',
      notice_desc_3_a: '完成安裝後請點擊 ',
      notice_desc_3_b: '[確認]',
      notice_desc_3_c: '。',
      notice_desc_4_a: '確認安裝後請點擊下面的 ',
      notice_desc_4_b: '[現在開始]',
      notice_desc_4_c: '。',
      btn_start: '現在開始',
      btn_install: '下載並安裝',
      confirm_text: '確認',
      yes_text: '是',
      no_text: '否',
      windows_only_game: '在 Windows 上可用。',
      check_for_installed_nxplug_question: '此電腦是否已安裝 NEXON Plug？',
      need_to_install_for_nxplug: '您需要 NEXON PLUG 才能遊玩此遊戲',
      move_to_nxplug_website: '進入 NEXON PLUG 安裝頁面。',
      execute_nxplug: '啟動 NEXON PLUG。',
    },
    ja: {
      common_game: 'ゲーム',
      common_game_default: 'ゲームがまもなくスタートします！',
      install_title: 'まもなくスタートします！',
      install_wait: '少々お待ちください。',
      install_desc_1: '自動でスタートしない場合はNEXON Game',
      install_desc_2: 'Managerをインストールしてください。',
      notice_welcome_en: '',
      notice_welcome_kr: ' へようこそ!',
      notice_welcome_default: 'ゲームへようこそ。',
      notice_desc_1_a: 'ダウンロードした',
      notice_desc_1_b: 'Install_NGM.exe',
      notice_desc_1_c: 'を実行してインストールしてください。',
      notice_desc_2_a: 'ユーザーアカウント制御で',
      notice_desc_2_b: 'Nexon Game Manager',
      notice_desc_2_c: 'を許可してください。',
      notice_desc_3_a: 'インストールが完了したら',
      notice_desc_3_b: '[確認]',
      notice_desc_3_c: 'をクリックしてください。',
      notice_desc_4_a: 'インストールを確認した後、',
      notice_desc_4_b: '[スタート]',
      notice_desc_4_c: 'をクリックしてください。',
      btn_start: 'スタート',
      btn_install: 'ダウンロード',
      confirm_text: '確認',
      yes_text: 'はい',
      no_text: 'いいえ',
      windows_only_game: 'Windowsで利用可能。',
      check_for_installed_nxplug_question: 'NEXON Plugはインストールされていますか？',
      need_to_install_for_nxplug: 'ゲームをプレイするにはNEXON PLUGが必要です。',
      move_to_nxplug_website: 'NEXON PLUGインストールページに移動します。',
      execute_nxplug: 'NEXON PLUGを起動する。',
    },
    en: {
      common_game: 'The game',
      common_game_default: 'The game will start soon!',
      install_title: 'Will start soon!',
      install_wait: 'Please wait for a moment.',
      install_desc_1: 'If it doesn\'t start automatically,',
      install_desc_2: 'NexonPlug is required to launch the game.',
      notice_welcome_en: 'How to install and launch ',
      notice_welcome_kr: '',
      notice_welcome_default: 'Welcome to the game!',
      notice_desc_1_a: 'Download and run ',
      notice_desc_1_b: 'Install_Nexonplug.pkg',
      notice_desc_1_c: '.',
      notice_desc_2_a: 'Accept the Software License',
      notice_desc_2_b: '',
      notice_desc_2_c: 'Agreement, then install NexonPlug.',
      notice_desc_3_a: 'After installation is completed, \nclick ',
      notice_desc_3_b: '[Close]',
      notice_desc_3_c: '.',
      notice_desc_4_a: 'When the plug is installed, click ',
      notice_desc_4_b: '[Start Now]',
      notice_desc_4_c: ' below.',
      btn_start: 'Start Now',
      btn_install: 'Download and Install',
      confirm_text: 'OK',
      yes_text: 'YES',
      no_text: 'NO',
      windows_only_game: 'Available on Windows.',
      check_for_installed_nxplug_question: 'Is NEXON Plug installed on this computer?',
      need_to_install_for_nxplug: 'You need NEXON PLUG to play the game.',
      move_to_nxplug_website: 'Go to the NEXON PLUG installation page.',
      execute_nxplug: 'Launch NEXON PLUG.',
    },
  },
}

let browserLang = 'en'
if (navigator.language.includes('-')) {
  const codes = navigator.language.split('-')
  if (codes[0] !== 'zh' && codes.length === 2) {
    const langCode = codes[0]
    browserLang = langCode
  } else {
    const twCodeList = ['zh-tw', 'zh-hant', 'zh-hant-tw']
    if (twCodeList.some((tw) => tw === navigator.language.toLocaleLowerCase())) {
      browserLang = 'zhhant'
    }
  }
} else {
  browserLang = navigator.language
}

const os = getOS()
const langList = Object.keys(translation[os])
const currentLang = langList.includes(browserLang) ? browserLang : 'en'
const getText = (textId: string) => {
  const list: { [key: string]: string } = translation[os][currentLang]

  return list[textId] || ''
}

const t = (id: string, options?: Record<string, any>) => {
  try {
    let text = ''

    if (id in translation[os][currentLang]) {
      text = getText(id)

      if (!!text && options) {
        text = text.replace(/#{([^{}]+)}/g, (_, key) => options[key] || '')
      }
    }

    console.log('id ', id, os)
    // 빈 문자 대신 ID 값으로 설정
    return text
  } catch (err) {
    return id
  }
}

export {
  t,
  currentLang,
}
